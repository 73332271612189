import React from 'react'
import CityPage from '../../components/cityPage'
import image from '../../images/cities/scale-model-of-davie-florida.png'
import image0 from "../../images/cities/scale-model-of-seminole-hard-rock-hotel-&-casino-in-davie-florida.png"
import image1 from "../../images/cities/scale-model-of-buehler-planetarium-in-davie-florida.png"

export default class LandingPage extends React.Component<{}, {}> {
  render() {
    return (
        <CityPage
            city='Davie'
            state='Florida'
            image={image}
            lat='26.0764783'
            lon='-80.25211569999999'
            attractions={ [{"name": "Seminole Hard Rock Hotel & Casino", "vicinity": "1 Seminole Way, Fort Lauderdale", "types": ["casino", "lodging", "point_of_interest", "establishment"], "lat": 26.0516862, "lng": -80.21208919999998}, {"name": "Buehler Planetarium", "vicinity": "3501 Davie Rd, Davie", "types": ["point_of_interest", "establishment"], "lat": 26.0797378, "lng": -80.23470179999998}] }
            attractionImages={ {"Seminole Hard Rock Hotel & Casino":image0,"Buehler Planetarium":image1,} }
       />);
  }
}